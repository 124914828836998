import React from 'react';
import logo from './logo.svg';
import './App.css';
import pic from './Images/1/p1.png'
import PhoneCard from './components/iPhoneCard'
import {Grid, Typography, Box} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AppleIcon from '@material-ui/icons/Apple';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
var iPhones = []

iPhones.push({"title": "Apple iPhone XS - 64GB - Black (Unlocked) (Boxed) Excellent Condition", "price": "£499", 
              "description": "Excellent condition. No damage or scratches everything in good working order (FaceID etc..). Comes with generic box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114353335629", "refid":"4", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone XS 64GB Gold (Unlocked) Boxed Very Good Condition", "price": "£470", 
              "description": "Excellent condition. Everything in good working order (FaceID etc..). Comes with original box and plug/cable. Unlocked", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114429818086", "refid":"9", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone XS - 256GB - Black (Unlocked) A2097 (GSM)", "price": "£499", 
              "description": "iphone XS 256GB Unlocked. Very good condition. A few light scratches to front. Everything works perfect FaceID.. etc. Comes with generic box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441549238", "refid":"15", "sold_status":"available"});           
iPhones.push({"title": "Apple iPhone XS - 64GB - Black (EE) Excellent Condition", "price": "£435", 
              "description": "Very good condition. Locked to EE but can be unlocked at the end of Nov for £8.99 (the phone has to be older than 6 months before it can be unlocked). Everything works perfect - FaceId, etc.. Comes with Generic Box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441372078", "refid":"8", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone 11 Pro Max - 512GB - Silver (Unlocked) ", "price": "£999", 
              "description": "The best iPhone Apple have ever released - this is the 512GB version of the iPhone Pro Max - unlocked. Excellent Camera, specs etc.. The phone shows several signs of usage with scuffs to sides, front and back - not noticeable when screen on. Everything works Face ID etc... ", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114454209816", "refid":"17", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone XS Max - 256GB - Space Grey (Unlocked)", "price": "£599", 
              "description": "This is a 256GB iphone XS Max in Grey/Silver Unlocked. In perfect working order with a few scuffs to side, screen.", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114454250705", "refid":"19", "sold_status":"available"});    
iPhones.push({"title": "Apple iPhone XS 512GB (Unlocked) - White/Silver", "price": "£599", 
              "description": "512GB iphone XS in White & Silver. Unlocked. Very good condition with several light signs of wear. Comes with generic box.", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114450653148", "refid":"18", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone XS - 64GB - Black (EE) Excellent Condition (Boxed)", "price": "£499", 
              "description": "Very good condition. Locked to EE. Everything works perfect - FaceId, etc.. Comes with Original Box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441382409", "refid":"11", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone XS - 64GB - Black (Unlocked)", "price": "£469", 
              "description": "Good Condition - Unlocked. Several light scratches to front and back. Everything works perfect FaceID etc.. Comes with Generic box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441399165", "refid":"14", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone 11 PRODUCT RED - 64GB (Vodafone)", "price": "£549", 
              "description": "Phone looks amazing - iphone 11 Product Red on vodafone (can be unlocked after 1 month of use if you prefer a different network as per Vodafone policy). Comes with box. Small scratch on front. Everything in perfect working order", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114450189982", "refid":"16", "sold_status":"available"});
iPhones.push({"title": "Apple iPhone 11 - 64GB - Black (Vodafone)", "price": "£499", 
              "description": "iphone 11 Black on vodafone 98% Battery Health. (you can unlock for free after 1 month if you prefer a different network). Good condition - a few small scuffs/scratches - please see photos. Comes with generic box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441517897", "refid":"12", "sold_status":"available"});

iPhones.push({"title": "Apple iPhone 11 - 64GB - Black (Vodafone)", "price": "£499", 
              "description": "Very good condition (98% Battery Health) - a few signs of use. Everything works as it should. Comes with generic box. On Vodafone but can be unlocked free after 1 month", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114440103578", "refid":"6", "sold_status":"sold"});
iPhones.push({"title": "Apple iPhone 11 Pro - 64GB - Space Grey/Black (Unlocked)", "price": "£599", 
              "description": "Very good condition (100% Battery Health) with small cosmetic crack near camera (doesnt effect any functionality) - several light scratches on screen not noticable when screen is on. Everything works as it should. Comes with generic box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114429849187", "refid":"10", "sold_status":"sold"});

              iPhones.push({"title": "Apple iPhone XS - 64GB - Black (Unlocked)", "price": "£480", 
              "description": "iphone XS Black 64GB (Unlocked) Excellent Condition", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441517897", "refid":"3", "sold_status":"sold"});
iPhones.push({"title": "Apple iPhone 11 - 64GB - Black (Unlocked)", "price": "£449", 
              "description": "100% battery Health, fully working (Face ID, Speaker, etc...) with no damage or cracks, Lots of cosmetic scuffs and scratches - please see photos - doesnt affect function of phone and hardly visible when phone is switched on. Unlocked, comes with generic box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114452324132", "refid":"5", "sold_status":"sold"});
iPhones.push({"title": "Apple iPhone XS - 256GB - Gold (Unlocked)", "price": "£549", 
              "description": "iphone XS 256GB Black (Unlocked) Very Good Condition", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441517897", "refid":"2", "sold_status":"sold"});
              iPhones.push({"title": "Apple iPhone XS 512GB Black (Unlocked)", "price": "£650", 
              "description": "iphone XS Black 512GB (Unlocked) Very Good Condition. Comes with Generic Box", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441517897", "refid":"7", "sold_status":"sold"});
iPhones.push({"title": "Apple iPhone XS - 64GB - White (Unlocked)", "price": "£499", 
              "description": "iphone 64GB XS White Unlocked.Perfect Condition", 
              "ebaylink" : "https://www.ebay.co.uk/itm/114441517897", "refid":"1", "sold_status":"sold"});   
var phoneCount = 0;
iPhones.forEach(function(phone){if (phone.sold_status == "available"){phoneCount++;}})
function App() {

  const classes = useStyles();
  function phoneClicked(phone){
    console.log("Phone clicked", phone)
  }


  return (
    <div className="App">
    <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" href="https://www.ebay.co.uk/sch/crosss1/m.html?item=114353335629&rt=nc&_trksid=p2047675.l2562">
            <AppleIcon />
          </IconButton>
          <Typography color="secondary" variant="h8" className={classes.title}>
         
          </Typography>
          <Button color="inherit" size="small" href="http://lankydonkey.x10host.com/">LankyDonkey</Button>
        </Toolbar>
      </AppBar>
      <Box m={5}>
      
  <p>Below is a list of {phoneCount} iPhones that we are currently selling on eBay. Due to the significant fees on eBay we can sell these items direct at a discount. If you are interested in purchasing a phone please get in touch below. We are based in North London, EN2 if you want to view the phones</p>
     
  
      <Grid container spacing = {4}>
                      {iPhones.map(phone =>(<PhoneCard sold_status={phone.sold_status} refid = {phone.refid} title = {phone.title} price = {phone.price} description = {phone.description  } ebaylink = {phone.ebaylink} onClick={()=>{phoneClicked(phone)}} key={phone.title}>SOME TEXT</PhoneCard>))   }
                      </Grid>
      {/* <img src={pic}/> */}
      </Box>
    </div>
  );
}

export default App;

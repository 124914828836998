import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Card, CardContent,CardActions,CardHeader} from '@material-ui/core';
import {Typography,Button,Grid} from '@material-ui/core';
import './iphonecard.css';

const useStyles = makeStyles({
    root:{
        minWidth: 275,
        backgroundColor: "primary",
    },
    title:{
        fontSize: 14,
    }
})

const PhoneCard = (props) => {
    const classes = useStyles()
    const title = props.title+ " (Ref#"+props.refid+")";
    const price = props.price;
    const description = props.description;
    const eBayLink = props.ebaylink;
    const emaillink = "mailto:katiedowle10@gmail.com?cc=alphakinetic@gmail.com&subject=Ref#"+props.refid+" -"+title+" ("+price+")"
    const sold_status = props.sold_status;
    const discount_price = Math.floor(price.replace("£","")*0.87)

    
    function openLink(url){
//        const url = 'somesite.com?data=yourDataToSend';
        window.open(url, '_blank');
    }
    return (
        <Grid item xs ={12} sm={4}>
        <Card className = {classes.root} variant = "outlined">
        
        <CardContent className={sold_status}>
        <CardHeader
            title = {title}
            subheader = {description}/>
            <Typography color = "green" variant="h2">
            {/* {sold_status=="sold"?<span className="sold"><s>{price} </s>SOLD</div>:<p>{price}</p>} */}
            {sold_status=="sold"?<span className="sold"><s>{price} </s>SOLD</span>:<span><s className="beforeprice">{price}</s> <span className="discount">£{discount_price}</span></span>}
            {/* <span><s className="beforeprice">{price}</s> <span className="discount">£{discount_price}</span></span> */}
            </Typography>
            {sold_status=="available"?(<CardActions>
                <Button size="small" color="secondary" variant="contained" onClick={()=>{openLink(eBayLink)}}>View on eBay</Button>
                <Button size="small" color="primary" href={emaillink} variant="contained" >Email Us</Button>
            </CardActions>):<div></div>
          }
            
            
        </CardContent>    
        </Card>
        </Grid>
    )
}


export default PhoneCard;